import {ACT_START_CHECKME} from '../../store/_act_consts';
import {
  MUT_SET_CHECK_ME_FUNC,
  MUT_SET_CHECK_ME_INFO,
  MUT_SHOW_ALERT,
} from '../../store/_mut_consts';

// kind : [1: 아이디/비밀번호찾기, 2: 회원가입 본인인증]
export const selfAuth = (store, target, kind) => {
  store.dispatch(`auth/${ACT_START_CHECKME}`, kind)
  .then(resp =>{
    if(resp.status && resp.status.code == 200) {
      const vm = target;
      let callResultCheckMe = null;
      if( kind === 1) {
        callResultCheckMe = function (info) {
          if (info && info.result === 0) {
            vm.isCheckMe = true;
            const agent = navigator.userAgent.toLowerCase();
            let isIe = false;
            // ie일 경우
            const infov = {};
            if ((navigator.appName == 'Netscape' && agent.indexOf('trident') != -1) || (agent.indexOf("msie") != -1)) {
              isIe = true;
              infov.reqno = info.reqno.split('').join('');
              infov.result = info.result;
              infov.encodeData = info.encodeData.split('').join('');
            }
            vm.$store.commit(`auth/${MUT_SET_CHECK_ME_INFO}`, (isIe ? infov : info));
            // vm.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            //   title: '본인인증이 완료되었습니다.',
            //   yesFunc: ()=>{}
            // });
            checkMeWindow.close();
            if (vm.checkMeInfoSuccess) {
              vm.checkMeInfoSuccess();
            }
          } else {
            vm.$store.commit(`common/${MUT_SHOW_ALERT}`, {
              title: '본인인증에 실패하였습니다.'
            });
          }
        }
      } else {
        callResultCheckMe = function (info) {
          if (info && info.result === 0) {
            vm.isCheckMe = true;
            const agent = navigator.userAgent.toLowerCase();
            let isIe = false;
            // ie일 경우
            const infov = {};
            if ((navigator.appName == 'Netscape' && agent.indexOf('trident') != -1) || (agent.indexOf("msie") != -1)) {
              isIe = true;
              infov.custBrdt = info.custBrdt.split('').join('');
              infov.gndrCd = info.gndrCd;
              infov.mblNo = info.mblNo.split('').join('');
              infov.name = info.name.split('').join('');
              infov.reqno = info.reqno.split('').join('');
              infov.result = info.result;
              infov.time = info.time.split('').join('');
              infov.encodeData = info.encodeData.split('').join('');
              infov.ci = info.ci.split('').join('');
            }

            vm.$store.commit(`auth/${MUT_SET_CHECK_ME_INFO}`, (isIe ? infov : info));
            checkMeWindow.close();
            if (vm.checkMeInfoSuccess) {
              vm.checkMeInfoSuccess();
            }
          } else {
            vm.$store.commit(`common/${MUT_SHOW_ALERT}`, {
              title: '본인인증에 실패하였습니다.'
            });
          }
        }
      }
      const callFuncs = {name: resp.reqno, callfunc: callResultCheckMe}
      store.commit(`auth/${MUT_SET_CHECK_ME_FUNC}`, callFuncs);
      fnPopup(resp.encData)
    } else {
      console.log("fail ..")
      store.commit(`common/${MUT_SHOW_ALERT}`, {
        title: '본인인증 준비가 실패하였습니다.'
      });
    }
  })
  .catch(e=>{
    console.error(e);
    store.commit(`common/${MUT_SHOW_ALERT}`, {
      title: '본인인증 준비가 실패하였습니다.'
    });
  })
};
