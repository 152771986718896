<template>
  <main class="page-container" :id="isFindId ? 'sme-recovery-id':'sme-recovery-password'">
    <div class="auth-component">
      <header class="auth-header">
        <h2 class="title">동행축제 {{ isFindId ? '아이디' : '비밀번호' }} 찾기</h2>
      </header>
      <div class="auth-body">
        <div class="auth-content">
          <h4 class="title">본인 명의의 휴대폰 번호로 인증 후 {{ isFindId ? '아이디를 알려드려요' : '비밀번호를 변경하세요' }}</h4>
          <small class="subtext">본인 주민등록번호로 가입 된 휴대폰 번호</small>
          <table v-if="isFindId && isSelfCheck" class="table gachi-auth-table mt-3">
            <thead class="tb-header">
            <tr>
              <th class="text-center">아이디</th>
              <th class="text-center">가입일자</th>
            </tr>
            </thead>
            <tbody class="tb-body">
            <tr v-for="(item, idx) in items" :key="idx">
              <td>{{ item.lgnId }}</td>
              <td>{{ regDtTimeFormat(item.regDt) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-if="!isFindId" class="auth-forms">
          <div class="form-row">
            <div class="form-celltd">
              <div class="form-group">
                <input v-model="lgnId" type="text" class="form-control" placeholder="아이디" :readonly="isSelfCheck"/>
                <template v-if="isSelfCheck">
                  <input v-model="userPasswd" type="password" class="form-control mt-1" placeholder="새 비밀번호 입력" />
                  <div class="text-left">
                    <small class="small-text" style="color: red" v-if="!passwordInit && !isPasswordRegx && userPasswd.length > 0">* 영문, 숫자, 특수문자 포함 8-16자리로 입력해주세요.</small>
                  </div>
                  <input v-model="userPasswdChk" type="password" class="form-control mt-1" placeholder="새 비밀번호 확인" />
                  <div class="text-left">
                    <small class="small-text" style="color: red" v-if="!isPasswordCheck && userPasswdChk.length > 0">* 비밀번호가 일치하지 않습니다.</small>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="auth-buttons">
          <button v-if="!isSelfCheck" class="btn btn-xl btn-primary" @click="checkMe">
            <span class="text">휴대폰 본인 인증</span>
          </button>
          <button v-else-if="isFindId" class="btn btn-xl btn-primary" @click="clickMove">
            <span class="text">비밀번호 찾기</span>
          </button>
          <button v-else-if="!isFindId" class="btn btn-xl btn-primary" @click="clickChangePassword">
            <span class="text">비밀번호 재설정</span>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {selfAuth} from '../../../assets/js/selfAuth';
import {MUT_SET_CHECK_ME_INFO, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM} from '../../../store/_mut_consts';
import {
  ACT_CHECK_FIND_ID,
  ACT_GET_USER_FIND_ID_LIST,
  ACT_GET_USER_FIND_PASSWORD,
  ACT_UPDATE_USER_PASSWORD_CHANGE,
} from '../../../store/_act_consts';
import {mapGetters} from 'vuex';
import {getItem, getItems, isSuccess, lengthCheck, strToHex, timestampToDateFormat} from '../../../assets/js/utils';
import {checkPassword} from '../../../assets/js/modules/validation';
import SHA512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';
export default {
  name: 'DhFindInfo',
  data: ()=>({
    isSelfCheck: false, // 본인인증 여부

    passwordInit: true,
    isPasswordCheck: false, // 비밀번호 확인
    isPasswordRegx: false, // 비밀번호 유효성

    items: [],

    lgnId: '',
    userPasswd: '',
    userPasswdChk: '',
    params: {
      userId: 0,
      mblNo: '',
      userPasswd: '',
    }
  }),
  computed: {
    ...mapGetters('auth', ['checkMeInfo']),
    isFindId(){
      return this.$route.params.division === 'id';
    }
  },
  watch: {
    userPasswd(){
      this.passwordInit = false;
      // 숫자 + 문자 9~20자리
      this.isPasswordRegx = checkPassword(this.userPasswd);
      if(this.isPasswordRegx){
        this.isPasswordCheck = this.userPasswd === this.userPasswdChk;
      }else{
        this.isPasswordCheck = true;
      }
    },
    userPasswdChk(){
      this.isPasswordCheck = this.userPasswd === this.userPasswdChk;
    },
  },
  methods: {
    checkMe() {
      let isId = true; // 비밀번호 찾기(아이디 입력 여부)
      if(!this.isFindId) {
        if(!this.lgnId){
          isId = false;
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: '아이디를 입력해주세요.'
          });
        }
      }

      if(isId) {
        this.$store.commit(`auth/${MUT_SET_CHECK_ME_INFO}`, {});
        selfAuth(this.$store, this,1);
      }
    },
    checkMeInfoSuccess() {
      const param = {includes : this.checkMeInfo.encodeData , filter: this.checkMeInfo.encodeData }
      this.$store.dispatch(`auth/${ACT_CHECK_FIND_ID}`, param)
      .then(resp => {
        if(lengthCheck(resp)){
          if(this.isFindId){
            this.getUserFindIds(resp);
          }else{
            this.getUserFindPassword(resp);
          }
        }
      })
    },
    regDtTimeFormat(regDt) {
      return timestampToDateFormat(regDt, 'yyyy.MM.dd');
    },
    getUserFindIds(params){
      const item = getItem(params);

      // 회원 아이디 찾기
      this.$store.dispatch(`auth/${ACT_GET_USER_FIND_ID_LIST}`, {
        userNm: item.name,
        mblNo: item.mibl
      }).then(res => {
        if(lengthCheck(res)){
          this.isSelfCheck = true;
          this.items = getItems(res);
        }else{
          this.items = [];
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: '존재하는 아이디가 없습니다.',
          });
        }
      }).catch(e => {
        console.error(e);
      });
    },
    getUserFindPassword(params){
      const paramItem = getItem(params);

      // 회원 비밀번호 찾기
      this.$store.dispatch(`auth/${ACT_GET_USER_FIND_PASSWORD}`, {
        userNm: paramItem.name,
        lgnId: this.lgnId,
        mblNo: paramItem.mibl
      }).then(res => {
        if(lengthCheck(res)){
          this.isSelfCheck = true;
          const item = getItem(res);
          this.params.userId = item.userId;
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: '가입된 정보가 없습니다.',
          });
        }
      }).catch(e => {
        console.error(e);
      });

    },
    clickMove(){
      this.$router.push({name: 'DhFindInfo', params: {division: 'password'}}).catch(()=>{});
      this.isSelfCheck = false;
    },
    clickChangePassword(){

      this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
        title: '비밀번호를 변경하시겠습니까?',
        showCancelButton: true,
        yesfunc: this.updateChangePassword,
      });

    },
    updateChangePassword(){
      this.params.userPasswd = strToHex(SHA512(this.userPasswd).toString(Base64));

      this.$store.dispatch(`auth/${ACT_UPDATE_USER_PASSWORD_CHANGE}`, this.params)
      .then(res=>{
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: '비밀번호가 변경되었습니다.',
            yesfunc: ()=>{
              // alert('동행축제 로그인 페이지로 이동');
              location.href = 'https://www.k-shoppingfesta.org/login';
            }
          });
        }
      });
    }
  }
};
</script>
